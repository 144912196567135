pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "museo-sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
  font-size: 1rem;
  box-sizing: border-box;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* 2 */
  --antd-wave-shadow-color: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "museo-sans", sans-serif;
  font-size: 1rem;
}

/* make sure consistent input heights */
input {
  min-height: 56px;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  -ms-word-break: break-all !important;
  word-break: break-word !important;
}

/* remove the padding in collaps panels */
.ant-collapse-content-box {
  padding: 0 !important;
}

ant-collapse-header
/* style for the Select component in AddTrainer view */
.add-trainer__select .ant-select-selection {
  white-space: nowrap;
  height: 50px;
  overflow: auto;
}

.add-trainer .ant-select-selection .ant-select-selection__rendered {
  line-height: 48px;
}

.sign-up .ant-form-item {
  margin: 0 auto;
  margin-bottom: 24px;
}

/* sweet alert customisation */
.custom_swal_container {
  background: rgba(0, 99, 112, 0.9) !important;
}

.custom_swal_title {
  text-align: left !important;
  color: #26252f !important;
  font-size: 1rem !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.custom_swal_actions {
  justify-content: flex-start !important;
  margin-top: 10px !important;
}

.custom_swal_button {
  margin: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  width: 45% !important;
  font-weight: 700 !important;
}

.custom_swal_popup {
  padding: 2rem !important;
  border-radius: 12px !important;
}
/* to override body margin from antd */
.sb-show-main.sb-main-padded {
  margin: 0;
  padding: 0;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0 !important;
}

::selection {
  background: #eeebf0 !important;
}

/* to override body margin from antd */
.sb-show-main.sb-main-padded {
  margin: 0;
  padding: 0;
}

/* to override elipsis on dropdown options from antd */
.ant-select-item-option-content {
  white-space: normal;
  height: auto;
}

.ant-select-dropdown-menu-item {
  font-size: 16px !important;
  padding: 8px 8px 8px 16px !important;
  color: #5a5961 !important;
}

.ant-select-selection__placeholder {
  color: #757575 !important;
}

.ant-select-dropdown-menu-item-selected {
  font-size: 16px !important;
  background-color: #eeebf0 !important;
  color: #31228e !important;
  padding: 8px 8px 8px 16px !important;
}

.ant-select-dropdown-menu-item-active {
  background-color: white;
  color: #5a5961 !important;
}

/* style popover */
.ant-popover {
  border-radius: 12px !important;
}

.ant-popover-inner {
  background: #31228e !important;
  border-color: #31228e !important;
  border-radius: 12px !important;
}

.ant-popover-arrow {
  background: #31228e !important;
  border-color: #31228e !important;
}

.ant-popover-inner-content {
  color: white !important;
}

.ant-tooltip-inner {
  text-align: center !important;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  min-height: unset;
}

.ant-calendar-picker-container {
  input, .ant-calendar-input-wrap {
    min-height: 40px;
  }
}
